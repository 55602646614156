<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-page-add' }"
              >
                <span class="mr-25 align-middle">Create page</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchPagesList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: title -->
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <!-- Column: slug -->
        <template #cell(slug)="data">
          {{ data.item.slug }}
        </template>

        <!-- Column: is_subgroup -->
        <template #cell(is_active)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.is_active)}`"
          >
            {{ data.item.is_active }}
          </b-badge>
        </template>

        <!-- Column: created_by -->
        <template #cell(created_by)="data">
          {{ data.item.created_by.full_name }}
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>

        <!-- Column: created_at -->
        <template #cell(updated_at)="data">
          {{ data.item.updated_at | formatDate }}
        </template>

        <!-- Column: created_at -->
        <template #cell(last_edited_by)="data">
          {{ data.item.last_edited_by.full_name }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deletePage(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BButton, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import moment from 'moment'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/pages/pagesStoreModule'
import usePagesList from '@/views/admin/pages/pages-list/usePagesList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    vSelect,

    TableSpinner,
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-cms-pages'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPagesList,
      deletePage,
      refetchData,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      actionOptions,
      resolveStatusVariant,
    } = usePagesList(root)

    return {
      fetchPagesList,
      deletePage,
      refetchData,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      actionOptions,

      resolveStatusVariant,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('pagesPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = Number(localStorage.getItem('pagesPerPage'))
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
